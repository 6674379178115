import React, { useContext } from 'react'
import styles from './CardLarge.module.scss'
import { Link } from '../'
import cn from 'classnames'
import { LayoutContext } from '../../contexts/layout'

const CardLarge = ({image, title, content, link, imageTopCard}) => {
  const { state: layoutState } = useContext(LayoutContext)
  const { redBg } = layoutState

  return (
    <div className={cn('col-12 col-md-6', styles.flex, { 'col-md-10 col-lg-6': !imageTopCard })}>
      <div className={styles.card}>
        <div className='row'>
          <div className={cn('col-12', { 'col-md-4': !imageTopCard })}>
            <div className={cn(styles.imageContainer, { [styles.imageTopCard]: imageTopCard })}>
              <img src={image} />
            </div>
          </div>
          <div className={cn('col-12', { 'col-md-8': !imageTopCard })}>
            <div className={cn(styles.content, { [styles.imageTopCard]: imageTopCard })}>
              <h3 className={cn(styles.title, {
                [styles.titleGray]: !redBg
              })}>{title}</h3>
              <div className={styles.innerContent} dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
          {link &&
            <Link className={styles.link} to={link}>Sjá nánar</Link>
          }
        </div>
      </div>
    </div>
  )
}

export default CardLarge

import React from 'react'
import styles from './CategorySection.module.scss'
import { CardLarge, Card, Swiper } from '../'
import cn from 'classnames'

const CategorySection = ({ title, description, cards = [] }) => {
  const CardCmp = cards.length > 2
    ? Card
    : CardLarge

  const imageTopCard = title === 'Internet' || title === 'Krakkakort'
  const Cards = () => cards.map(({ ...props }, i) => <CardCmp key={i} {...props} imageTopCard={imageTopCard} />)

  return (
    <div className={styles.container}>
      <div className='row'>
        <div className='col-12'>
          <div className={styles.heading}>
            <h2>{title}</h2>
            {description &&
              <p>{description}</p>
            }
          </div>
        </div>
      </div>
      <div className={cn('row', styles.cardsContainer)}>
        {cards && cards.length > 2 &&
        <div className='col-12'>
          <Swiper>
            {cards.map(({ ...props }, i) => <CardCmp key={i} {...props} />)}
          </Swiper>
        </div>
        }
        {cards && cards.length < 3 &&
          <Cards imageTopCard={imageTopCard} />
        }
      </div>
    </div>
  )
}

export default CategorySection

import React, { useContext } from 'react'
import styles from './CategoryIntro.module.scss'
import { Parallax } from 'react-scroll-parallax'
import { LayoutContext } from '../../contexts/layout'
import cn from 'classnames'

const CategoryIntro = ({ description }) => {
  const { state: layoutState } = useContext(LayoutContext)
  const { redBg } = layoutState

  return (
    <Parallax y={['-180px', '100px']}>
      <div className='row'>
        <div className='col-12'>
          <div className={cn(styles.text, { [styles.isGrey]: !redBg })}>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </Parallax>
  )
}

export default CategoryIntro

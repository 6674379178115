import React from 'react'
import styles from './GradientBackground.module.scss'
import cn from 'classnames'
import { Parallax } from 'react-scroll-parallax'

const GradientBackground = () => {
  const colors = [
    '#0099DA',
    '#F39500',
    '#A8B500',
    '#F39500'
  ]
  const gradients = [
    {
      x: '0',
      y: '30'
    },
    {
      x: '120',
      y: '100'
    },
    {
      x: '990',
      y: '30'
    },
    {
      x: '550',
      y: '-50'
    }
  ]

  return (
    <div className={styles.container}>
      {gradients.map(({x, y}, i) => {
        const animationClass = `anim${i + 1}`
        const color = colors[i]
        return (
          <Parallax key={i} y={['-100px', '100px']}>
            <div
              className={cn(styles.grad, styles[animationClass])}
              style={{
                left: `${x}px`,
                top: `${y}px`,
                background: `radial-gradient(ellipse at center, ${color} 0%,rgba(125,185,232,0) 70%)`
              }}
            />
          </Parallax>
        )
      })}
    </div>
  )
}

export default GradientBackground

import React, { Fragment, useState, useEffect, useContext } from 'react'
import Helmet from 'react-helmet'
import '../../styles/index.scss'
import cn from 'classnames'
import { GradientBackground } from '../../components'
import { LayoutContext } from '../../contexts/layout'

const MainLayout = ({ children }) => {
  const { state: layoutState, dispatch } = useContext(LayoutContext)
  const { redBg } = layoutState

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)

    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  }, [])

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const enableRed = winScroll > 100
    const dispatchType = enableRed
      ? 'disableRedBg'
      : 'enableRedBg'

    dispatch({
      type: dispatchType
    })
  }

  return (
    <Fragment>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>Vodafone Sumar</title>
        <link data-react-helmet='true' rel='icon' type='image/png' sizes='32x32' href='https://vodafone.is/library/icons2/Brand-2017/apple-icon-180x180.png'></link>
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='http://vodafone.is/sumar' />
        <meta property='og:title' content='Vodafone Sumar' />
        <meta property='og:description' content='Verum vel tengd um allt land í sumar.' />
        <meta property='og:image' content='https://vodafone.is/lisalib/getfile.aspx?itemid=237197ca-acb2-11ea-80eb-00505681d681' />
        { /* eslint-disable */ }
        <meta data-react-helmet='true' name='description' content='' />
        { /* eslint-enable */ }
        <html lang='is' />
      </Helmet>

      <nav className='sr-only' aria-label='skip to main content'>
        <a href='#main' className='sr-only'>Skip to main content</a>
      </nav>

      <GradientBackground />
      <main
        id='main'
        className={cn({
          'background-grey': !redBg
        })}
      >
        {children}
      </main>
    </Fragment>
  )
}

export default MainLayout

import React, { useContext } from 'react'
import styles from './Card.module.scss'
import { Link } from '../'
import cn from 'classnames'
import { LayoutContext } from '../../contexts/layout'

const Card = ({image, title, content, link}) => {
  const { state: layoutState } = useContext(LayoutContext)
  const { redBg } = layoutState

  return (
    <div className='swiper-slide'>
      <div className={styles.card}>
        <div className='row'>
          <div className='col-12'>
            <div className={styles.imageContainer}>
              <img src={image} />
            </div>
          </div>
          <div className={cn('col-12', styles.flex)}>
            <div className={styles.content}>
              <h3 className={cn(styles.title, {
                [styles.titleGray]: !redBg
              })}>{title}</h3>
              <div className={styles.innerContent} dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
          {link &&
            <Link className={styles.link} to={link}>Sjá nánar</Link>
          }
        </div>
      </div>
    </div>
  )
}

export default Card

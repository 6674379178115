import React, { useLayoutEffect, useState } from 'react'
import styles from './Hero.module.scss'
import sumarPng from '../../assets/sumar@2x.png'
import sumarPngMobile from '../../assets/sumar-mobile@2x.png'
import cn from 'classnames'
import { Parallax } from 'react-scroll-parallax'
import { useWindowDimensions } from '../../utils'

const Hero = ({ isHomepage }) => {
  const [shouldUseMobileImage, setShouldUseMobileImage] = useState(false)
  const { width } = useWindowDimensions()
  const heroClasses = cn(styles.mainHeading, {
    [styles.isHomepage]: isHomepage
  })

  const image = width && shouldUseMobileImage
    ? sumarPngMobile
    : sumarPng

  useLayoutEffect(() => {
    setShouldUseMobileImage(width < 769)
  })

  return (
    <Parallax y={['-300px', '100px']}>
      <div className={heroClasses}>
        <img src={image} />
      </div>
    </Parallax>
  )
}

export default Hero

import React from 'react'
import styles from './Categories.module.scss'
import cn from 'classnames'
import { Link } from '@reach/router'

const Categories = ({ categories }) => {
  return (
    <div className={styles.container}>
      <div className='row'>
        {categories.map(({title, image, uid}, i) => {
          return (
            <Link
              to={`/${uid}`}
              key={i}
              className={cn('col-12 col-md-6', styles.category)}
            >
              <div
                style={{ backgroundImage: `url(${image})` }}
                className={styles.image}
              />
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Categories

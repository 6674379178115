import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({
  children,
  to,
  className,
  activeClassName,
  openBlank,
  ...props
}) => {
  // test to see if url is internal
  const internal = openBlank ? false : /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        {...props}
        activeClassName={activeClassName}
        className={className}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a
      href={to}
      target='_blank'
      rel='noopener noreferrer'
      {...props}
      className={className}
    >
      {children}
    </a>
  )
}

export default Link

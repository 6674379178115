import React, { useState, useRef, useLayoutEffect } from 'react'
import Swiper from 'swiper'
import ReactResizeDetector from 'react-resize-detector'
import classNames from 'classnames'

const Slider = ({ children, ...props }) => {
  const [swiper, setSwiper] = useState(null)
  const scrollContainer = useRef(null)

  const enableSwiper = () => {
    setSwiper(new Swiper(
      scrollContainer.current,
      { ...props }
    ))
  }

  const disableSwiper = () => {
    if (swiper) {
      swiper.destroy()
    }
  }

  useLayoutEffect(() => {
    enableSwiper()
    return () => {
      disableSwiper()
    }
  }, [])

  const onResize = () => {
    if (swiper) {
      swiper.update()
    }
  }

  return (
    <div className='slider-container'>
      <div
        ref={scrollContainer}
        className={classNames('swiper-container')}>

        <div className={classNames('swiper-wrapper')}>
          {children}
        </div>
      </div>
      <div className={classNames('swiper-pagination')} />

      {/* bootstrap styles race condition fix */}
      <ReactResizeDetector handleWidth onResize={onResize} />
    </div>
  )
}

/* https://idangero.us/swiper/api/#parameters */
Slider.defaultProps = {
  slidesPerView: 1.2,
  slidesPerGroup: 1,
  spaceBetween: 10,
  grabCursor: true,
  freeMode: false,
  freeModeMomentumBounce: false,
  freeModeMomentumRatio: 0.5,
  preventClicksPropagation: false,
  preventClicks: true,
  onTap: (sw, e) => {
    e.preventDefault()
    sw.allowClick = false
  },
  mousewheel: {
    forceToAxis: true
  },
  breakpoints: {
    500: {
      slidesPerView: 1.2,
      slidesPerGroup: 1
    },
    700: {
      slidesPerView: 1.7,
      slidesPerGroup: 1
    },
    900: {
      slidesPerView: 2.5,
      slidesPerGroup: 1
    },
    1100: {
      slidesPerView: 4,
      slidesPerGroup: 1
    }
  }
}

export default Slider

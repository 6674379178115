import React from 'react'
import { Link } from '../../components'
import styles from './Header.module.scss'
import logo from '../../assets/vodafonelogo.png'
import { useLocation } from '@reach/router'

const Header = () => {
  const location = useLocation()
  const { pathname } = location

  const linkTo = pathname === '/'
    ? 'https://vodafone.is'
    : '/'
  const linkText = pathname === '/'
    ? 'Fara aftur á vodafone.is'
    : 'Fara til baka'

  return (
    <div className={'container'}>
      <div className='row'>
        <div className='col-12'>
          <div className={styles.innerContainer}>
            <Link to={linkTo}>
              <img src={logo} />
              <h2>{linkText}</h2>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
